import React from 'react';
import PropTypes from 'prop-types';

import stylesheet from './Checkbox.less';

const CheckboxDescription = ({ id, children }) => {
  return (
    <div id={id} className={stylesheet.description}>
      {children}
    </div>
  );
};

CheckboxDescription.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default CheckboxDescription;
