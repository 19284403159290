import React from 'react';
import PropTypes from 'prop-types';
import CharacterCounter from './CharacterCounter';
import stylesheet from './InputLabel.less';

function InputLabel({
  children,
  hasFocus = false,
  htmlFor,
  maxLength,
  renderCharacterCount,
  secondaryLabel = null,
  shouldShowCharacterCount = true,
  value,
}) {
  return (
    <div className={stylesheet.label}>
      <label htmlFor={htmlFor} className={stylesheet.primary}>
        {children}
      </label>
      <span className={stylesheet.secondary}>
        {typeof value === 'string' &&
        hasFocus &&
        shouldShowCharacterCount &&
        maxLength ? (
          <CharacterCounter currentLength={value.length} maxLength={maxLength}>
            {renderCharacterCount}
          </CharacterCounter>
        ) : (
          secondaryLabel
        )}
      </span>
    </div>
  );
}

InputLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]),
  hasFocus: PropTypes.bool,
  htmlFor: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  renderCharacterCount: PropTypes.func,
  secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  shouldShowCharacterCount: PropTypes.bool,
  value: PropTypes.any,
};

export default InputLabel;
