import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylesheet from './CharacterCounter.less';

/**
 * This is used to display the number of characters remaining for an input.
 * This does not account for emoji being more than one character. In the future
 * we may need to consider that for places that emoji can be used.
 */
function CharacterCounter({
  currentLength = 0,
  maxLength,
  dangerThreshhold = 10,
  children = ({ remaining }) =>
    `${remaining} ${remaining === 1 ? 'character' : 'characters'} remaining`,
}) {
  const remaining = maxLength - currentLength;
  return (
    <span
      className={classNames({
        [stylesheet.danger]: remaining <= dangerThreshhold,
      })}
    >
      {children({ currentLength, maxLength, remaining })}
    </span>
  );
}

CharacterCounter.propTypes = {
  children: PropTypes.func,
  currentLength: PropTypes.number,
  dangerThreshhold: PropTypes.number,
  maxLength: PropTypes.number.isRequired,
};

export default CharacterCounter;
