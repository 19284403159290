export const MAILCHIMP_URL =
  'https://mailchimp.com/referral/?utm_source=freemium_survey&utm_medium=survey&utm_campaign=referral_marketing';

export const MAILCHIMP_LOGO_URL =
  'https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg';

export const ABUSE_URL = 'https://mailchimp.com/contact/abuse/';

export const SURVEYS_OVERVIEW_PATH = '/lists/surveys';
export const ALL_SURVEYS_PATH = '/lists/surveys/all-surveys';
export const CREATE_SURVEY_PATH = '/lists/surveys/create';
export const SURVEY_DETAILS_PATH = '/lists/surveys/details?survey_id=';
export const SURVEY_RESULTS_PATH = '/lists/surveys/results?survey_id=';
export const SURVEY_EDIT_PATH = '/lists/surveys/edit?survey_id=';
export const SURVEY_REPLICATE_PATH = '/lists/surveys/replicate?survey_id=';
export const SURVEY_DELETE_PATH_BASE = '/lists/surveys/delete';
