import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { generateRandomString } from '@mc/fn/rando';
import { getNestedValue, setNestedValue } from '@mc/fn/nestedValue';
import FormContext from './FormContext';
import FormField from './FormField';
import SubmitButton from './SubmitButton';
import ResetButton from './ResetButton';
import useFormField from './useFormField';

/**
 * Form handling.
 * ## Overview
 * `<Form>` is a wrapper that can be thought of as a replacement for the HTML `<form>` tag. Under-the-hood it manages all the values, errors, and state of a form and provides an `onSubmit` callback for submitting those values to an API, local storage, etc.
 *
 * There a few child components for `<Form>`:
 * - `<FormField />`: Takes a `component` prop and a `name` prop and uses it to map the form state to the given component. The `component` should be a form input of some sort (see `@mc/components/Input` for most cases) but will allow any controlled component (a component that takes both an `onChange` callback and `value` prop). FormField will handle labels, ARIA roles, descriptions, and error messages from the Form and pass them into the given input component.
 *
 * - `<SubmitButton />`: a wrapper around `@mc/components/Button` that is aware of the forms current validity and will trigger the onSubmit hook.
 *
 * - `<ResetButton />`: `@mc/components/Button` that will trigger the resetForm hook.
 *
 * ## Usage
 *
 * ### Basic
 *
 * ```jsx
 * import Form, { FormField, SubmitButton } from '@mc/components/Form';
 * import { isEmail, isRequired. compose } from '@mc/validation';
 *
 * function Example() {
 *   const handleSubmit = (formData) => {
 *     return doSomethingWithFormData(formData);
 *   };
 *
 *   return (
 *     <Form
 *       initialValues={{
 *         foo: null,
 *         qux: 'a@bc.biz',
 *         bar: 'hello',
 *       }}
 *       onSubmit={handleSubmit}
 *       validation={{
 *       foo: isRequired('You must enter a foo.'),
 *       qux: compose(isEmail('Qux must be an email address'), isRequired('You must enter a qux')),
 *     }}>
 *         <FormField component={InputText} name="foo" label="Foo" />
 *         <FormField component={InputText} name="bar" label="Bar">
 *             <p>Bar</p>
 *         </FormField>
 *         <SubmitButton type="primary">Save</SubmitButton>
 *     </Form>
 *   );
 * }
 * ```
 *
 * ### Nested values
 *
 * ```jsx
 * import Form, { FormField, SubmitButton } from '@mc/components/Form';
 * import { isEmail, isRequired. compose } from '@mc/validation';
 *
 * function Example() {
 *   const handleSubmit = (formData) => {
 *     return doSomethingWithFormData(formData);
 *   };
 *
 *   return (
 *     <Form
 *       initialValues={{
 *         baz: {
 *           nested: 'a nested value'
 *         }
 *       }}
 *       onSubmit={handleSubmit}
 *       validation={{
 *       'baz.nested': (value) => {
 *         if (value.length > 200) {
 *           return 'too big';
 *         }
 *         return null;
 *       }
 *     }}>
 *         <FormField component={TextArea} name="baz.nested" label="Nested Baz" />
 *         <SubmitButton type="primary">Save</SubmitButton>
 *     </Form>
 *   );
 * }
 * ```
 *
 * ### Inputs with children
 *
 * ```jsx
 * import Form, { FormField, SubmitButton } from '@mc/components/Form';
 * import { isEmail, isRequired. compose } from '@mc/validation';
 *
 * function Example() {
 *   const handleSubmit = (formData) => {
 *     return doSomethingWithFormData(formData);
 *   };
 *
 *   return (
 *     <Form
 *       initialValues={{
 *         selectVal: "foo",
 *         radioVal: "a"
 *       }}
 *       onSubmit={handleSubmit}
 *       validation={{
 *         selectVal: isRequired("Please select an option"),
 *         radioVal: isRequired("Please select an option")
 *       }}
 *     >
 *        <FormField
 *          label="Example Select Value"
 *          name="selectVal"
 *          component={(props) => (
 *            <Select {...props}>
 *              <Option aria-label="foo" value="foo">Foo</Option>
 *              <Option aria-label="bar" value="bar">Bar</Option>
 *            </Select>
 *          )}
 *        />
 *        <FormField
 *          label="Example Radio Value"
 *          name="radioVal"
 *          component={(props) => (
 *            <RadioGroup {...props}>
 *               <Radio value="a">Foo</Radio>
 *               <Radio value="b">Bar</Radio>
 *           </RadioGroup>
 *         )}
 *       />
 *       <SubmitButton type="primary">Save</SubmitButton>
 *     </Form>
 *   );
 * }
 * ```
 */
class Form extends Component {
  static propTypes = {
    /** Pass autoComplete="off" as a prop to disable browser autocompletion for the form */
    autoComplete: PropTypes.string,
    /** Children can either be a normal set of nodes, ideally comprising of `<FormField>`s, `<SubmitButton>`, `<ResetButton>`, etc. OR a function that will give you direct access to the current form state */
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    /** An optional class name to apply to the form element **/
    className: PropTypes.string,
    /** This will toggle which type of field indicator will show for the entire form: required or optional. */
    hasRequiredLabel: PropTypes.bool,
    /** If `id` is not supplied, an id of `form-[a random number]` will be generated automatically. All FormFields will inherit the form's id to create their own ids of `formID-fieldName`.  */
    id: PropTypes.string,
    /**
     * If you need to initialize the form in a non-pristine state you can pass
     * initial state values here. **This mostly exists for rare circumstances.
     * Avoid using this if at all possible.**
     */
    initialState: PropTypes.shape({
      hasSubmitted: PropTypes.bool,
      isSubmitting: PropTypes.bool,
      touched: PropTypes.array,
    }),
    /** The values to set as the forms "initial state". These are used when the form is initially created to populate field values and then if the form is reset these values are restored. */
    initialValues: PropTypes.object,
    /** Any time a value is changed, onChange is triggered. This callback has two arguments: the first is an object containing only the changed value(s), the second is the entire changed form data set. */
    onChange: PropTypes.func,
    /** When a form submit event is fired, this callback is triggered. If a promise is returned in this callback, the promise is honored before completing. It has one argument which is the form values in the current form state. This will only trigger if the form is considered "valid", see the "validation" prop for more. If an object is returned here containing keys of `values` and/or `errors` they will be merged in with the existing values and errors form state. Be careful to not accidentally return those values as it could result in accidentally erasing your form state. */
    onSubmit: PropTypes.func.isRequired,
    /** FormFields will automatically show this indicator for fields not marked as `required`, if the hasRequiredLabel is also defined as `optional` */
    /** @ignore Not meant to be changed. Use miscText prop for custom copy.*/
    optionalFieldIndicator: PropTypes.string,
    /** FormFields will automatically show this indicator for fields marked as `required`, if the hasRequiredLabel is also defined as `required` */
    /** @ignore Not meant to be changed. Use miscText prop for custom copy. */
    requiredFieldIndicator: PropTypes.string,

    /** Pass scrollToErrors="true" as a prop to jump to the first error on validation */
    scrollToErrors: PropTypes.bool,
    /** Validation can come in two syntaxes:
     *  1. An object whos values are functions. Each function is run independently and if it returns null, is removed from the resulting errors object, otherwise it expects to be a string. This should usually map directly to the fields defined for the form.
     *
     * For most common validation patterns, check out `@mc/validation` for standard functions that can be mapped directly to the field.
     *
     * 2. a function that receives all the values of the given form state and should return either null if there are no errors or an object of keys and error messages as their values for any errors received. Use this method only if the validation pattern is complex and syntax 1 can't handle it easily.
     *
     * `<FormField>` will forward any errors that match its name to the consuming component. For example if the result errors object looks like: `{ foo: 'There was an error' }`, then `<FormField name="foo" component={Bar} />` would automatically show the error message and set a prop of `isInvalid` to `true` to the `Bar` component.
     */
    validation: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.func),
      PropTypes.func,
    ]),
  };

  static defaultProps = {
    hasRequiredLabel: true,
    initialState: {
      hasSubmitted: false,
      isSubmitting: false,
      touched: [],
    },
    initialValues: {},
    onChange: () => {},
    // To be translated
    optionalFieldIndicator: 'Optional',
    // To be translated
    requiredFieldIndicator: 'Required',
    validation: {},
  };

  constructor(props) {
    super(props);
    const { errors, isValid } = this.validate(
      props.validation,
      props.initialValues,
    );
    this.formRef = React.createRef();
    this.state = {
      isSubmitting: props.initialState.isSubmitting,
      hasSubmitted: props.initialState.hasSubmitted,
      formId: props.id || `form-${generateRandomString()}`,
      resetKey: generateRandomString(),
      values: props.initialValues,
      touched: props.initialState.touched,
      requiredFieldIndicator:
        props.hasRequiredLabel === true ? props.requiredFieldIndicator : '',
      optionalFieldIndicator:
        props.hasRequiredLabel === false ? props.optionalFieldIndicator : '',
      errors,
      isValid,
      scrollToErrors: props.scrollToErrors,
      setValue: this.setValue,
      submitForm: this.submitForm,
      resetForm: this.resetForm,
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validate(rules, values) {
    let errors = {};
    if (typeof rules === 'function') {
      errors = rules(values);
    } else {
      errors = Object.keys(rules).reduce((memo, key) => {
        const value = getNestedValue(values, key);
        const error = rules[key](value, values);
        if (error) {
          memo[key] = error;
        }

        return memo;
      }, {});
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors,
    };
  }

  setValue = (name) => {
    return (value) => {
      this.setState(
        (prevState) => {
          const values = setNestedValue(prevState.values, name, value);
          const { errors, isValid } = this.validate(
            this.props.validation,
            values,
          );
          const touched = Array.from(new Set([...prevState.touched, name]));
          return {
            errors,
            isValid,
            touched,
            values,
          };
        },
        () =>
          this.props.onChange(
            setNestedValue({}, name, value), // return only the nested changed value
            this.state.values,
          ),
      );
    };
  };

  resetForm = () => {
    const { validation, initialValues } = this.props;
    const { errors, isValid } = this.validate(validation, initialValues);
    this.setState({
      resetKey: generateRandomString(),
      values: initialValues,
      touched: [],
      isSubmitting: false,
      hasSubmitted: false,
      isValid,
      errors,
    });
  };

  submitForm = (additionalValues = {}) => {
    const { values } = this.state;
    const { validation, onSubmit } = this.props;
    const data = { ...values, ...additionalValues };
    const { isValid, errors } = this.validate(validation, data);

    this.setState({
      isSubmitting: isValid,
      hasSubmitted: true,
      isValid,
      errors,
    });

    if (isValid) {
      return Promise.resolve(onSubmit(data)).then((result) => {
        if (this._isMounted) {
          this.setState((prevState) => {
            // If we detect values or errors returned from the onSubmit handler, merge them into the form state
            const valuesAfterSubmit =
              result && result.values
                ? { ...prevState.values, ...result.values }
                : prevState.values;
            const errorsAfterSubmit =
              result && result.errors
                ? { ...prevState.errors, ...result.errors }
                : prevState.errors;
            return {
              values: valuesAfterSubmit,
              errors: errorsAfterSubmit,
              isValid: Object.keys(errorsAfterSubmit).length === 0,
              isSubmitting: false,
            };
          });
        }
        // Making the UI jump to error messages when clicking "submit"
        if (this.props.scrollToErrors === true && this.formRef?.current) {
          this.jumpToErrorMessages();
        }
      });
    }
    return null;
  };

  jumpToErrorMessages = () => {
    const form_errors = this.formRef.current.querySelector('[data-form-error]');
    if (form_errors !== null) {
      // Jump to the first error on the page
      form_errors.parentNode.scrollIntoView({ behavior: 'smooth' });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.submitForm();
  };

  render() {
    const { children } = this.props;
    return (
      // This is the bread and butter of this as what we do here is centralize all the form state into <Form>
      // but using context we provide each field with a consumer of its value and change hooks to update it.
      <FormContext.Provider value={this.state}>
        <form
          className={this.props.className}
          noValidate
          key={this.state.resetKey}
          onSubmit={this.handleSubmit}
          autoComplete={this.props.autoComplete}
          ref={this.formRef}
          id={this.state.formId}
        >
          {typeof children === 'function'
            ? children({ ...this.state })
            : children}
        </form>
      </FormContext.Provider>
    );
  }
}

export {
  Form as default,
  FormContext,
  useFormField,
  FormField,
  SubmitButton,
  ResetButton,
};
