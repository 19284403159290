import { generateRandomString } from '@mc/fn/rando';
import { Bugsnag } from '@mc/bugsnag';

const QUESTION_RESPONSE_TYPES = [
  'pickOne',
  'pickMany',
  'email',
  'range',
  'text',
  'contactInformation',
];

export const generateUniqueValue = (knownValues) => {
  let value;

  do {
    value = generateRandomString();
  } while (knownValues.indexOf(value) >= 0);

  return value;
};

export const getSectionTypeString = (sectionTypeEnum) => {
  switch (sectionTypeEnum) {
    case 'introduction':
      return 'Introduction';
    case 'context':
      return 'Text (No Question)';
    default:
      throw new Error('Section input type not recognized');
  }
};

export const isQuestionResponse = (value) => {
  return QUESTION_RESPONSE_TYPES.includes(value);
};

export const rangeTypes = [
  {
    lowRangeValue: 0,
    highRangeValue: 5,
    rangeLabel: '0 - 5',
    rangePresentation: 'number',
  },
  {
    lowRangeValue: 1,
    highRangeValue: 5,
    rangeLabel: '1 - 5',
    rangePresentation: 'number',
  },
  {
    lowRangeValue: 0,
    highRangeValue: 7,
    rangeLabel: '0 - 7',
    rangePresentation: 'number',
  },
  {
    lowRangeValue: 1,
    highRangeValue: 7,
    rangeLabel: '1 - 7',
    rangePresentation: 'number',
  },
  {
    lowRangeValue: 0,
    highRangeValue: 10,
    rangeLabel: '0 - 10',
    rangePresentation: 'number',
  },
  {
    lowRangeValue: 1,
    highRangeValue: 10,
    rangeLabel: '1 - 10',
    rangePresentation: 'number',
  },
  {
    lowRangeValue: 1,
    highRangeValue: 5,
    rangeLabel: '⭐⭐⭐⭐⭐',
    rangePresentation: 'star',
  },
  {
    lowRangeValue: 1,
    highRangeValue: 5,
    rangeLabel: '😫 🙁 😐 🙂 😁',
    rangePresentation: 'smiley',
  },
  {
    lowRangeValue: 1,
    highRangeValue: 5,
    rangeLabel: '❤️❤️❤️❤️❤️',
    rangePresentation: 'heart',
  },
];

export const getRangeTypeFromLabel = (label) => {
  return rangeTypes.find(({ rangeLabel }) => rangeLabel === label);
};

export const getRangeLabel = (
  minRangeValue = 0,
  maxRangeValue = 10,
  presentation = 'number',
) => {
  const { rangeLabel } = rangeTypes.find(
    ({ lowRangeValue, highRangeValue, rangePresentation }) => {
      return (
        lowRangeValue === minRangeValue &&
        highRangeValue === maxRangeValue &&
        rangePresentation === presentation
      );
    },
  );

  return rangeLabel;
};

export const getRangeIncrements = (lowRangeValue, highRangeValue) => {
  return [...Array(highRangeValue - lowRangeValue + 1).keys()].map(
    (num) => num + lowRangeValue,
  );
};

export const getEmojiDisplayValues = (emoji, lowRangeValue, highRangeValue) => {
  let prefix = '';
  return new Array(highRangeValue - lowRangeValue + 1).fill('').map(() => {
    prefix = `${prefix}${emoji}`;
    return prefix;
  });
};

export const getRangeDisplayValues = (
  presentation,
  lowRangeValue,
  highRangeValue,
) => {
  switch (presentation) {
    case 'number':
      return getRangeIncrements(lowRangeValue, highRangeValue).map(String);
    case 'star':
      return getEmojiDisplayValues('⭐', lowRangeValue, highRangeValue);
    case 'heart':
      return getEmojiDisplayValues('❤️', lowRangeValue, highRangeValue);
    case 'smiley':
      return ['😫', '🙁', '😐', '🙂', '😁'];
    default:
      throw new Error('Range presentation not recognized');
  }
};

export const RANGE_CONDITIONS = ['==', '!=', '>', '>=', '<', '<='];

export const SURVEY_CLOSED_MESSAGE =
  'This survey is no longer accepting responses.';
export const MISSING_ANSWER_DEFAULT = 'Question skipped';
