import { useEffect, useState } from 'react';

/**
 * @var NEVER_CLEAR hook clear configuration, tells the hook not to clean the url
 */
export const NEVER_CLEAR = 0;

/**
 * @var CLEAR_ONCE_IN_MEMORY hook clear configutation tells the hook to clear once the state is in memory
 */
export const CLEAR_ONCE_IN_MEMORY = 1;

const getWindow = () => {
  if (typeof global !== 'undefined' && global.window) {
    return global.window;
  }
  return window;
};

const globalHistory = getWindow().history;
const globalLocation = getWindow().location;

const globalPushState = globalHistory.pushState;

// copied over from @mc/router/history
function createHistoryKey() {
  return Date.now() + '';
}

const constructState = (stateData) => {
  const index =
    typeof globalHistory.state?.index === 'number'
      ? globalHistory.state.index
      : 0;
  return Object.assign(stateData, {
    key: createHistoryKey(),
    index: index + 1,
    // Persist the current location as the referrer for the next entry.
    referrer: globalLocation.href,
  });
};

/**
 * Gets a url query parameter and stores it in memory.
 *
 * @returns {{ value: any }}
 */
export default function useQueryToState(
  label,
  defaultValue,
  options = { clear: NEVER_CLEAR },
) {
  const [value, setValue] = useState({ isLoading: true, value: defaultValue });
  const { clear = NEVER_CLEAR } = options;
  useEffect(() => {
    const url = new URL(globalLocation);
    if (url.searchParams.has(label)) {
      setValue({ isLoading: false, value: url.searchParams.get(label) });
    } else {
      setValue({ isLoading: false, value: defaultValue });
    }
    if (clear === CLEAR_ONCE_IN_MEMORY && url.searchParams.has(label)) {
      url.searchParams.delete(label);
      globalPushState(
        constructState({
          [label]: undefined,
        }),
        'unused message',
        url.toString(),
      );
    }
  }, [setValue, clear, label, defaultValue]);
  return value;
}
