import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mc/components/Button';
import FormContext from './FormContext';

function SubmitButton({ isLoading, disabledWhenInvalid = true, ...props }) {
  const { isSubmitting, isValid } = useContext(FormContext);
  const isDisabledBecauseInvalid = disabledWhenInvalid && !isValid;
  return (
    <Button
      {...props}
      isLoading={isSubmitting || isLoading}
      disabled={isSubmitting || isDisabledBecauseInvalid || props.disabled}
      htmlType="submit"
    />
  );
}

SubmitButton.propTypes = {
  /**
   * Disables the button (same effect as the disabled attribute on a button element). Please use this sparingly as
   * SubmitButton mostly handles disabled states automatically for you.
   * */
  disabled: PropTypes.bool,
  /**
   * Set this to true if you want invalid form states to disable the button.
   */
  disabledWhenInvalid: PropTypes.bool,
  /**
   * Use this if an outside entity is causing a loading state that needs to be shown. Form submission will set this
   * to true internally so no need to track that for `onSubmits`.
   */
  isLoading: PropTypes.bool,
};

export default SubmitButton;
