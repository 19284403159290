import React, { forwardRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import isValidElementType from '@mc/fn/isValidElementType';
import useFormField from './useFormField';
import stylesheet from './FormField.less';

/**
 * @deprecated Use the Form component from mc/wink instead.
 *
 */
const FormField = forwardRef(
  (
    {
      name,
      className,
      component: InputComponent,
      children,
      description,
      id,
      required = false,
      descriptionClassName,
      ...props
    },
    ref,
  ) => {
    const Description = InputComponent.Description || 'div';
    const {
      id: defaultId,
      value,
      onChange,
      error,
      isValid,
      isTouched,
      formContext,
    } = useFormField(name);

    const fieldId = id || defaultId;
    const describedBy = [];
    const shouldShowAsInvalid =
      (isTouched || formContext.hasSubmitted) && !isValid;

    if (shouldShowAsInvalid) {
      describedBy.push(`${fieldId}-error`);
    }

    if (description) {
      describedBy.push(`${fieldId}-description`);
    }

    const secondaryLabel = required
      ? formContext.requiredFieldIndicator
      : formContext.optionalFieldIndicator;

    const inputProps = {
      id: fieldId,
      'aria-describedby': describedBy.join(' ') || undefined,
      isInvalid: shouldShowAsInvalid,
      description,
      required,
      ref,
      secondaryLabel,
      ...props,
      name,
      value,
      onChange,
    };

    return (
      <div className={cx(stylesheet.fieldWrapper, className)}>
        {isValidElementType(InputComponent) ? (
          <InputComponent {...inputProps} />
        ) : (
          InputComponent(inputProps)
        )}

        {description && (
          <Description
            id={`${fieldId}-description`}
            className={cx(stylesheet.description, descriptionClassName)}
          >
            {description}
          </Description>
        )}
        {error && shouldShowAsInvalid && (
          <div
            id={`${fieldId}-error`}
            className={stylesheet.invalidError}
            data-form-error
          >
            {error}
          </div>
        )}
        {children}
      </div>
    );
  },
);

FormField.propTypes = {
  children: PropTypes.node,
  /** CSS classname to add. Appends to the `<div>` component which is the root element. */
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  description: PropTypes.node,
  descriptionClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  optionalFieldIndicator: PropTypes.string,
  required: PropTypes.bool,
  requiredFieldIndicator: PropTypes.string,
  type: PropTypes.string,
};

export default FormField;
