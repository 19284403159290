import React from 'react';
import PropTypes from 'prop-types';
import stylesheet from './MultipleChoice.less';
import useId from '@mc/hooks/useId';

const MultipleChoiceOption = ({
  label,
  value,
  checked,
  onChange,
  question,
}) => {
  const id = useId();

  return (
    <label className={stylesheet.label} htmlFor={id}>
      <input
        className={stylesheet.radioInput}
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        name={question.id}
      />
      <span className={stylesheet.labelText}>{label}</span>
    </label>
  );
};

MultipleChoiceOption.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default MultipleChoiceOption;
