import React from 'react';
import PropTypes from 'prop-types';
import stylesheet from './SurveyContainer.less';
import {
  MAILCHIMP_URL,
  MAILCHIMP_LOGO_URL,
  ABUSE_URL,
} from '../../shared/constants';

function FooterBar() {
  return (
    <div className={stylesheet.footerBar}>
      <div className={stylesheet.footerBarContent}>
        <a
          className={stylesheet.privacyLink}
          target="_blank"
          href={ABUSE_URL}
          rel="noopener noreferrer"
        >
          Report abuse
        </a>
        <a
          className={stylesheet.createdWithLink}
          target="_blank"
          href={MAILCHIMP_URL}
          rel="noopener noreferrer"
        >
          <img
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            alt="Intuit Mailchimp logo"
            src={MAILCHIMP_LOGO_URL}
            className={stylesheet.createdWithLogo}
          />
        </a>
      </div>
    </div>
  );
}

function SurveyContainer({ survey, children }) {
  const hostedStyles = survey.hostedStyles;
  const styles = {};
  if (hostedStyles.backgroundColor) {
    styles.backgroundColor = hostedStyles.backgroundColor;
  }

  return (
    <React.Fragment>
      <div className={stylesheet.outerContainer} style={styles}>
        <div className={stylesheet.innerContainer}>{children}</div>
      </div>
      {hostedStyles.footerBrandingEnabled && (
        <section className={stylesheet.footerContainer}>
          <FooterBar />
        </section>
      )}
    </React.Fragment>
  );
}

SurveyContainer.propTypes = {
  children: PropTypes.node,
  survey: PropTypes.object.isRequired,
};

export default SurveyContainer;
