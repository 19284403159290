import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SurveyTextarea from '../SurveyTextArea';
import stylesheet from './Text.less';
import { MAX_TEXT_LIMIT } from '../constants';
import QuestionLabel from '../QuestionLabel';

const Text = ({ handleEditResponse, question, onChange, value }) => {
  return (
    <div className="question text">
      <div
        className={classNames('input-wrapper', stylesheet.textInputContainer)}
      >
        <SurveyTextarea
          label={<QuestionLabel question={question} />}
          maxLength={MAX_TEXT_LIMIT}
          onChange={onChange}
          value={value}
          shouldGrowWithContent
          minRows={3}
          handleEditResponse={handleEditResponse}
        />
      </div>
    </div>
  );
};

Text.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.string,
};

export default Text;
