import React from 'react';
import PropTypes from 'prop-types';
import Question from '../Question';
import { FormField } from '@mc/components/Form';

function QuestionFormField({ handleEditResponse, question }) {
  return question ? (
    <FormField
      name={'questions.' + question.id + '.answer'}
      label={question.inquiry}
      component={(props) => (
        <Question
          question={question}
          handleEditResponse={handleEditResponse}
          {...props}
        />
      )}
    />
  ) : null;
}

QuestionFormField.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
};

export default QuestionFormField;
