import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import QuestionLabel from '../QuestionLabel';
import InputText from '@mc/components/Input/InputText';

const SingleLineText = ({ question, onChange, value, handleEditResponse }) => {
  const MAX_CHAR_LENGTH = 255;

  return (
    <div className="question text">
      <div className={classNames('input-wrapper')}>
        <InputText
          type="text"
          label={<QuestionLabel question={question} />}
          onChange={(result) => {
            onChange({ freeform: result });
          }}
          value={value?.freeform}
          maxLength={MAX_CHAR_LENGTH}
          renderCharacterCount={() => {}}
          onBlur={handleEditResponse}
        />
      </div>
    </div>
  );
};

SingleLineText.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.shape({
    freeform: PropTypes.string,
  }),
};

export default SingleLineText;
