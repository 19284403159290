import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylesheet from './Number.less';
import QuestionLabel from '../QuestionLabel';
import InputText from '@mc/components/Input/InputText';

const Number = ({ question, onChange, value, handleEditResponse }) => {
  return (
    <div className="question text">
      <div
        className={classNames('input-wrapper', stylesheet.numberInputContainer)}
      >
        <InputText
          className={stylesheet.numberInputContainer}
          id={question.id.toString()}
          label={<QuestionLabel question={question} />}
          onChange={(result) => {
            onChange({ freeform: result });
          }}
          value={value?.freeform}
          type="number"
          onBlur={() => {
            handleEditResponse();
          }}
        />
      </div>
    </div>
  );
};

Number.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.shape({
    freeform: PropTypes.string,
  }),
};

export default Number;
