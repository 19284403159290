import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import stylesheet from './Button.less';

/**
 * Wrapper around the HTML button element.
 *
 * @deprecated Use the Button component from mc/wink instead.
 *
 */
const Button = React.forwardRef(
  (
    {
      children,
      className,
      clearMargin,
      htmlType = 'button',
      isLoading = false,
      isNormalWeightText = false,
      onClick,
      type,
      ...props
    },
    ref,
  ) => {
    return (
      // The react/button-has-type rule does not like references
      // and will complain about this usage, despite it being okay.
      <button
        ref={ref}
        disabled={isLoading}
        // eslint-disable-next-line react/button-has-type
        type={htmlType}
        onClick={onClick}
        className={cx(
          stylesheet.container,
          {
            [stylesheet.important]: type === 'important',
            [stylesheet.primary]: type === 'primary',
            [stylesheet.secondary]: type === 'secondary',
            [stylesheet.tertiary]: type === 'tertiary',
            [stylesheet.dropdownTrigger]: type === 'dropdownTrigger',
            [stylesheet.clearMargin]: clearMargin === true,
            [stylesheet.normalWeight]:
              type === 'tertiary' && isNormalWeightText,
            [stylesheet.loading]: isLoading,
          },
          className,
        )}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.propTypes = {
  /** The content inside the button */
  children: PropTypes.node.isRequired,
  /** A custom class name that is appended to the button classes. */
  className: PropTypes.string,
  /** Sets the default button margins to zero */
  clearMargin: PropTypes.bool,
  /** Makes the button unclickable */
  disabled: PropTypes.bool,
  /** While most HTML attributes of button are passed along. The "type" property is used to describe the button's variant type (see below). To accommodate this, we rename the 'type' attribute to 'htmlType'. */
  htmlType: PropTypes.oneOf(['submit', 'reset', 'button']),
  /** When true, the button is disabled and also shows a loading indicator */
  isLoading: PropTypes.bool,
  /** Changes the font weight to normal (Only used by tertiary buttons) */
  isNormalWeightText: PropTypes.bool,
  /** Mouse click event */
  onClick: PropTypes.func,
  /** The different variants of a Button */
  type: PropTypes.oneOf([
    'dropdownTrigger',
    'important',
    'primary',
    'secondary',
    'tertiary',
  ]).isRequired,
};

export default Button;
