import React from 'react';
import PropTypes from 'prop-types';
import Address from './../Address';
import Dropdown from './../Dropdown';
import MultipleChoice from './../MultipleChoice';
import Number from './../Number';
import Phone from './../Phone';
import SingleLineText from '../SingleLineText';

const ContactInformation = ({ handleEditResponse, question, ...props }) => {
  const mergeField = question.merge_field;
  if (mergeField) {
    switch (mergeField.type) {
      case 'dropdown':
        return (
          <Dropdown
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'text':
        return (
          <SingleLineText
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'radio':
        return (
          <MultipleChoice
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'number':
        return (
          <Number
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'phone':
        return (
          <Phone
            question={question}
            format={mergeField.options.phoneformat}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'address':
        return (
          <Address
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      default:
        return (
          <SingleLineText
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
    }
  } else {
    return <></>;
  }
};

ContactInformation.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.any,
};

export default ContactInformation;
