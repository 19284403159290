import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionText.less';

function SectionText({ section }) {
  return (
    <div
      className={styles.textContainer}
      data-testid="SectionTextContainer"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: section.text }}
    />
  );
}

SectionText.propTypes = {
  section: PropTypes.object.isRequired,
};

export default SectionText;
