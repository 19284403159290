import React from 'react';
import PropTypes from 'prop-types';
import CheckboxOption from './CheckboxOption';
import Other from '../Common/Other';
import { OTHER_VALUE } from '../constants';
import QuestionLabel from '../QuestionLabel';

const Checkbox = ({ handleEditResponse, question, onChange, value }) => {
  value = value ? value : {};

  const otherOptionLabel = question.properties.otherLabel || 'Other';

  const selectedValues = value.keys ? value.keys : [];
  const otherTextValue = value.freeform ? value.freeform : '';

  const handleCheckedInput = async (checked, inputValue) => {
    const updatedValues = {};

    if (checked) {
      updatedValues.keys = [...selectedValues, inputValue];
    } else {
      updatedValues.keys = selectedValues.filter((i) => i !== inputValue);
    }

    if (updatedValues.keys.includes(OTHER_VALUE)) {
      updatedValues.freeform = otherTextValue;
    }

    await onChange(updatedValues);
    handleEditResponse();
  };

  const handleOtherTextChange = (text) => {
    onChange({ keys: selectedValues, freeform: text });
  };

  const renderOtherResponse = () => {
    if (selectedValues.includes(OTHER_VALUE)) {
      return (
        <Other
          value={otherTextValue}
          onChange={handleOtherTextChange}
          handleEditResponse={handleEditResponse}
        />
      );
    }
  };

  const renderOther = () => {
    if (question.properties.hasOther) {
      return (
        <>
          <CheckboxOption
            label={otherOptionLabel}
            value={OTHER_VALUE}
            checked={selectedValues.includes(OTHER_VALUE)}
            onChange={handleCheckedInput}
            key={OTHER_VALUE}
          />
          {renderOtherResponse()}
        </>
      );
    }
  };

  return (
    <div className="question checkbox">
      <QuestionLabel question={question} />
      <div className="responses">
        <div className="input-wrapper">
          {question.properties.options.map((option) => {
            return (
              <CheckboxOption
                label={option.label}
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={handleCheckedInput}
                key={option.value}
              />
            );
          })}
          {renderOther()}
        </div>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.shape({
    freeform: PropTypes.string,
    keys: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default Checkbox;
