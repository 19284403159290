import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useId from '@mc/hooks/useId';
import InputLabel from '@mc/components/Input/InputLabel';
import stylesheet from '@mc/components/Input/Textarea.less';

/**
 * A wrapper around the native browser `<textarea />`, as well as a `<label>` and other sensible defaults.
 *
 * ## Usage
 * Textarea can be used stand-alone:
 * ```js
 * import Textarea from '@mc/Input/Textarea';
 *
 * function StandaloneExample() {
 *   const [foo, setFoo] = useState('');
 *   return (
 *     <Textarea onChange={setFoo} value={foo} label="Foo" />
 *   />
 *   );
 * }
 *```
 *
 * Or as the component of a `FormField` (which must be nested inside `<Form>`), in which the required values will automatically be supplied from the `Form` context.:
 * ```js
 * import { FormField } from '@mc/components/Form';
 * import Textarea from '@mc/Input/Textarea';
 *
 * function FormFieldExample() {
 *   return (
 *     <FormField component={Textarea} />
 *   />
 *   );
 * }
 * ```
 */
const SurveyTextarea = React.forwardRef(function SurveyTextarea(
  {
    className,
    handleEditResponse,
    id,
    isInvalid,
    isLabelVisible = true,
    isResizable = false,
    isStrictMaxLength = true,
    label: ComponentLabel,
    onChange = () => {},
    secondaryLabel,
    maxLength,
    renderCharacterCount,
    value = '',
    shouldGrowWithContent = false,
    minRows = 3,
    maxRows,
    ...props
  },
  ref,
) {
  const [hasFocus, setHasFocus] = useState(false);
  const [rows, setRows] = useState(minRows);
  const autoRef = useRef();
  const autoId = useId();
  const _id = id || autoId;
  const _ref = ref || autoRef;

  useEffect(() => {
    // 18 is the default line height in the app
    const rowsNeeded = Math.floor(_ref.current.scrollHeight / 18);
    if (shouldGrowWithContent && rowsNeeded > minRows) {
      setRows(rowsNeeded > maxRows ? maxRows : rowsNeeded);
    }
  }, [_ref, maxRows, minRows, shouldGrowWithContent, value]);

  return (
    <div className={classNames(stylesheet.container, className)}>
      {isLabelVisible && (
        <InputLabel htmlFor={_id} value={value} hasFocus={hasFocus}>
          {ComponentLabel}
        </InputLabel>
      )}
      {typeof ComponentLabel === 'string' && !isLabelVisible && (
        <span className="wink-visually-hidden">
          <label htmlFor={_id}>{ComponentLabel}</label>
        </span>
      )}
      {typeof ComponentLabel !== 'string' && !isLabelVisible && (
        <span className="wink-visually-hidden">
          {/* eslint-disable-next-line react/no-danger */}
          <label htmlFor={_id} dangerouslySetInnerHTML={ComponentLabel} />
        </span>
      )}
      <textarea
        id={_id}
        value={value}
        ref={_ref}
        maxLength={isStrictMaxLength ? maxLength : undefined}
        onChange={(event) => onChange(event.target.value)}
        rows={rows}
        onBlur={() => {
          setHasFocus(false);
          handleEditResponse();
        }}
        onFocus={() => setHasFocus(true)}
        {...props}
        className={classNames(stylesheet.textarea, {
          [stylesheet.invalid]: isInvalid,
          [stylesheet.resizable]: isResizable,
        })}
      />

      <div style={{ height: '1em' }}>
        <InputLabel
          htmlFor={_id}
          value={value}
          secondaryLabel={secondaryLabel}
          renderCharacterCount={renderCharacterCount}
          maxLength={maxLength}
          hasFocus={hasFocus}
        />
      </div>
    </div>
  );
});

SurveyTextarea.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  /** Passed to id attribute of the input element. Autogenerated if one is not passed as a prop. */
  id: PropTypes.string,
  /** When true, invalid visual styles will be applied. */
  isInvalid: PropTypes.bool,
  /** When false, replaces the label with an invisible version. */
  isLabelVisible: PropTypes.bool,
  /** When true, makes textarea resizable by user (up to 800px) */
  isResizable: PropTypes.bool,
  /** When false, the max length will not be applied to the underlying textarea but the character counter will still render */
  isStrictMaxLength: PropTypes.bool,
  /** Text to use for the form label. Passed to the `InputLabel` component. */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Sets a limit on how many characters can be supplied to the textarea (just like the default HTML attribute). If supplied, `InputLabel` will show a character count. */
  maxLength: PropTypes.number,
  /** Sets the number of maximum rows the textarea can expand to */
  maxRows: PropTypes.number,
  /** Sets default initial number of rows of the textarea and minimum rows */
  minRows: PropTypes.number,
  /** Required to enforce that this component should always be controlled. */
  onChange: PropTypes.func.isRequired,
  /** Passed to `InputLabel`. A template to render the character count output.  */
  renderCharacterCount: PropTypes.func,
  /** Passed to `InputLabel`. Used to render secondary information on the right side of the label. */
  secondaryLabel: PropTypes.node,
  /** Height of textarea will grow along side user content */
  shouldGrowWithContent: PropTypes.bool,
  /** Required to enforce that this component should always be controlled. */
  value: PropTypes.string,
};

export default SurveyTextarea;
