import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useId from '@mc/hooks/useId';
import InputLabel from './InputLabel';
import stylesheet from './InputText.less';

/**
 * A wrapper around the native browser `<input />`, as well as a `<label>` and other sensible defaults.
 *
 * ## Usage
 * InputText can be used stand-alone:
 * ```js
 * import InputText from '@mc/Input/InputText';
 *
 * function StandaloneExample() {
 *   const [foo, setFoo] = useState('');
 *   return (
 *     <InputText onChange={setFoo} value={foo} label="Foo" />
 *   />
 *   );
 * }
 *```
 *
 * Or as the component of a `FormField` (which must be nested inside `<Form>`), in which the required values will automatically be supplied from the `Form` context.:
 * ```js
 * import { FormField } from '@mc/components/Form';
 * import InputText from '@mc/Input/InputText';
 *
 * function FormFieldExample() {
 *   return (
 *     <FormField component={InputText} />
 *   />
 *   );
 * }
 * ```
 */
const InputText = forwardRef(
  (
    {
      className,
      id,
      isInvalid,
      isLabelVisible = true,
      isStrictMaxLength = true,
      label,
      prefix,
      onChange = () => {},
      secondaryLabel,
      suffix,
      maxLength,
      renderCharacterCount,
      type = 'text',
      value = '',
      ...props
    },
    ref,
  ) => {
    const [hasFocus, setHasFocus] = useState(false);
    const autoId = useId();
    const _id = id || autoId;

    return (
      <div className={classNames(stylesheet.container, className)}>
        {isLabelVisible ? (
          <InputLabel
            htmlFor={_id}
            label={label}
            secondaryLabel={secondaryLabel}
            value={value}
            renderCharacterCount={renderCharacterCount}
            maxLength={maxLength}
            hasFocus={hasFocus}
          >
            {label}
          </InputLabel>
        ) : (
          <span className="wink-visually-hidden">
            <label htmlFor={_id}>{label}</label>
          </span>
        )}
        <div className={stylesheet.inputWrapper}>
          {prefix && <span className={stylesheet.inputPrefix}>{prefix}</span>}
          <input
            ref={ref}
            type={type}
            className={classNames(stylesheet.input, {
              [stylesheet.invalid]: isInvalid,
            })}
            id={_id}
            value={value}
            maxLength={isStrictMaxLength ? maxLength : undefined}
            onChange={(event) => onChange(event.target.value)}
            onBlur={() => setHasFocus(false)}
            onFocus={() => setHasFocus(true)}
            {...props}
          />
          {suffix && <span className={stylesheet.inputSuffix}>{suffix}</span>}
        </div>
      </div>
    );
  },
);

InputText.propTypes = {
  /** Optional class to apply to the container. */
  className: PropTypes.string,
  /** Passed to id attribute of the input element. Autogenerated if one is not passed as a prop. */
  id: PropTypes.string,
  /** When true, invalid visual styles will be applied. */
  isInvalid: PropTypes.bool,
  /** When false, replaces the label with an invisible version. */
  isLabelVisible: PropTypes.bool,
  /** When false, the max length will not be applied to the underlying textarea but the character counter will still render */
  isStrictMaxLength: PropTypes.bool,
  /** Text to use for the form label. Passed to the `InputLabel` component. */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Sets a limit on how many characters can be supplied to the input (just like the default HTML attribute). If supplied, `InputLabel` will show a character count. */
  maxLength: PropTypes.number,
  /** Required to enforce that this component should always be controlled. */
  onChange: PropTypes.func.isRequired,
  /** If passed in, this will render to the left of the input. An example of this might be a currency indicator or a url */
  prefix: PropTypes.node,
  /** Passed to `InputLabel`. A template to render the character count output.  */
  renderCharacterCount: PropTypes.func,
  /** Passed to `InputLabel`. Used to render secondary information on the right side of the label. */
  secondaryLabel: PropTypes.node,
  /** If passed in, this will render to the right of the input. */
  suffix: PropTypes.node,
  /** Just the HTML attribute for `<input>`s. Defaults to `text`. Feel free to use any of the type variants of 'text' ('email', 'password', 'search', 'tel', 'url')
   *  but please do not use `checkbox`, `radio`, `file`, `submit`, `reset`, `hidden`, or the date variants. These are handled by other components in `@mc`.  */
  type: PropTypes.oneOf([
    'text',
    'email',
    'number',
    'password',
    'search',
    'tel',
    'url',
    'date',
  ]),
  /** Required to enforce that this component should always be controlled. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InputText;
