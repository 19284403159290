import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SingleLineText from '../SingleLineText';
import PhoneUS from './PhoneUS';

const Phone = ({ format, onChange, question, value, handleEditResponse }) => {
  const FORMAT_US = 'US';
  const FORMAT_INTERNATIONAL = 'none';

  return (
    <div className="question text">
      <div className={classNames('input-wrapper')}>
        {format === FORMAT_US && (
          <PhoneUS
            question={question}
            onChange={onChange}
            value={value}
            handleEditResponse={handleEditResponse}
          />
        )}
        {format === FORMAT_INTERNATIONAL && (
          <SingleLineText
            question={question}
            onChange={onChange}
            value={value}
            handleEditResponse={handleEditResponse}
          />
        )}
      </div>
    </div>
  );
};

Phone.propTypes = {
  format: PropTypes.oneOf(['US', 'none']),
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.shape({
    freeform: PropTypes.string,
    object: {
      area: PropTypes.number,
      detail1: PropTypes.number,
      detail2: PropTypes.number,
    },
  }),
};

export default Phone;
