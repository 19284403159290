import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './QuestionLabel.less';

const QuestionLabel = React.forwardRef(({ className, question }, ref) => {
  return (
    <div ref={ref}>
      <div
        data-testid="questionContainer"
        className={cx(
          className || '',
          styles.questionText,
          styles.questionTextHtmlContainer,
        )}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html:
            question.inquiry +
            (question.isRequired
              ? `<span data-testid="questionRequired">*</span>`
              : ''),
        }}
      />
    </div>
  );
});

QuestionLabel.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

export default QuestionLabel;
